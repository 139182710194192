import {useStaticQuery, graphql} from 'gatsby';


export const GetDonationPageFR = () =>  {
	
	const content = useStaticQuery(graphql`
	query DonationPageFR {
		allMarkdownRemark(
		  filter: {frontmatter: {lang: {eq: "fr"}}, fields: {collection: {eq: "DonationPage"}}}
		) {
			edges {
				node {
				  frontmatter {
					donationTitle
					cryptopopup {
						title
						subtitle
						content
						crypto {
							name
							adress
							qr{
								childImageSharp{
									fixed{
										src
									}
								}
							}
						}
					}
					donateSubTitle
					donationContent
					donationContact
					donationCards {
							logo{
								childImageSharp {
									fixed {
									  src
									}
								}
							}
							name
							description
							button
							link
							available
							className
						}
					}
				}
			  }
			}
		}
`);

	return content.allMarkdownRemark.edges[0].node.frontmatter;
};