import React, {useContext} from 'react';
import Layout from '../components/template/layout';
import LangContext from '../components/context/langContext';
import { GetDonationPageSeoFR } from '../content/pages/donation/seo/en/GetDonationPageSeo';
import { GetDonationPageSeoEN } from '../content/pages/donation/seo/fr/GetDonationPageSeo';
import DonationBlock from '../components/donation/donationBlock';

const Donate = () => {
    const ctx = useContext(LangContext)
    const seo = ctx.lang === 'fr' ? GetDonationPageSeoFR() : GetDonationPageSeoEN()
    return (
        <Layout
            title={seo.pageTitle}
            description={seo.pageDescription}
            lang={ctx.lang}
            page="donate"
        >
            <DonationBlock
                lang={ctx.lang}
            />
        </Layout>
    );
};

export default Donate;