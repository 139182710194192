import {useStaticQuery, graphql} from 'gatsby';


export const GetDonationPageEN = () =>  {
	
	const content = useStaticQuery(graphql`
	query DonationPageEN {
		allMarkdownRemark(
		  filter: {frontmatter: {lang: {eq: "en"}}, fields: {collection: {eq: "DonationPage"}}}
		) {
		  edges {
			node {
			  frontmatter {
                donationTitle
                donateSubTitle
				cryptopopup {
					title
					subtitle
					content
					crypto {
						name
						adress
						qr{
							childImageSharp{
								fixed{
									src
								}
							}
						}
					}
				}
                donationContent
                donationContact
                donationCards {
                        logo{
							childImageSharp {
								fixed {
								  src
								}
							}
						}
                        name
                        description
                        button
                        link
                        available
						className
                    }
				}
			}
		  }
		}
	  }
`);

	return content.allMarkdownRemark.edges[0].node.frontmatter;
};