import React, { useState } from 'react';
import { GetDonationPageEN } from '../../content/pages/donation/content/en/GetDonationPage';
import { GetDonationPageFR } from '../../content/pages/donation/content/fr/GetDonationPage';
import './donation.scss';
import Img from 'gatsby-image';
import CryptoPopup from './cryptoPopup';
import MailTo from '../utils/mailTo';

const DonationBlock = ({lang}) => {
    const content = lang === 'fr' ? GetDonationPageFR() : GetDonationPageEN()
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div>
            <div className="content-container">
                <h2   
                    data-sal="slide-up"
                    data-sal-delay="300"
                    data-sal-easing="ease"
                >{content.donationTitle}</h2>
                <h4>{content.donateSubTitle}</h4>
                <p>{content.donationContent}</p>
                <p>{content.donationContact}</p>
            </div>
            <div className="donation-card-container">{content.donationCards.map((e,i)=> (
                <div className={`donation-card ${e.className}`}>
                    <Img fixed={e.logo.childImageSharp.fixed}/>
                    <p>{e.name}</p>
                    <p>{e.description}</p>
                    {e.className === 'crypto' &&
                    <button onClick={() => setIsOpen(!isOpen)} style={{pointerEvents: e.available ? '' : 'none'}}>
                        {e.button}
                    </button>
                    }
                    {e.className !== 'crypto' && e.className !== 'other' &&
                    <button style={{pointerEvents: e.available ? '' : 'none'}}>
                        <a href={e.link}>{e.button}</a>
                    </button>
                    }
                    {e.className === 'other' &&
                    <button style={{pointerEvents: e.available ? '' : 'none'}}>
                        <MailTo
                            subject="Autres demandes - de-foundation"
                        >{e.button}</MailTo>
                    </button>
                    }
                </div>
            ))}</div>   
            <CryptoPopup setIsOpen={setIsOpen} lang={lang} open={isOpen} content={content.cryptopopup}/>
        </div>
    );
};

export default DonationBlock;
