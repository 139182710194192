import React from 'react';
import Img from 'gatsby-image';
import './cryptopopup.scss';

const CryptoPopup = ({lang, open, setIsOpen, content}) => {
    return (
        <div 
        className="cryptopup"
            role="dialog"
            onClick={() => setIsOpen(false)}
            style={{
                boxSizing: 'border-box',
                width: '100%',
                height: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                display: open ? 'block' : 'none',
                height: '100vh',
                overflow: 'scroll',
        }}>
            <div className="crypto-container">
                <h3   data-sal="slide-up"
  data-sal-delay="300"
  data-sal-easing="ease">{content.title}</h3>
                <p>{content.subtitle}</p>
                <p>{content.content}</p>
                {content.crypto.map((e,i) => (
                    <div>
                        <p>{e.name}</p>
                        <p>{e.adress}</p>
                        <Img fixed={e.qr.childImageSharp.fixed} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CryptoPopup;